import React, { Fragment } from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogSec from "../components/sections/BlogSec";
import LastNews from "../components/sections/LastNews";
import { LanguageContext } from "../store/language_context";

function Blogs() {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          "https://albaitdash.mountwolfs.com/api/articles",
          {
            headers: {
              locale: language,
            },
          }
        );
        setHomeData(data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);
  return (
    <Fragment>
      {/* Header */}
      <Header
        contacts={homeData.Contacts}
        siteIdentities={homeData.SiteIdentities}
        navLinks={homeData.NavLinks}
      />

      <div className="Blogs">
        {/* BlogSec */}
        <BlogSec articles={homeData.LatestArticles} />

        {/* Last News */}
        <LastNews articles={homeData.ArticlesTabs} />
      </div>

      {/* Footer */}
      <Footer />
    </Fragment>
  );
}

export default Blogs;
