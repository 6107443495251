import React from "react";
import { Link } from "react-router-dom";

const Soc1 = "/media/whats.png";
const Soc2 = "/media/w2.png";
const Soc3 = "/media/w3.png";
const Soc4 = "/media/w4.png";
const Soc5 = "/media/w5.png";

const SocialCon = ({ contacts }) => {
  const whatsApp = contacts?.find((contact) => contact.option === "whatsapp");
  const twitter = contacts?.find((contact) => contact.option === "x_url");
  const instagram = contacts?.find((contact) => contact.option === "instagram");
  const snapchat = contacts?.find((contact) => contact.option === "snapchat");
  const tiktok = contacts?.find((contact) => contact.option === "tiktok");
  const insta = contacts?.find((contact) => contact.option === "instagram");

  return (
    <div className="social-ico">
      <Link to={whatsApp?.value} target="_blank">
        <img src={Soc1} alt="whatsapp" />
      </Link>

      <Link to={twitter?.value} target="_blank">
        <img src={Soc2} alt="twitter" />
      </Link>

      <Link to={tiktok?.value} target="_blank">
        <img src={Soc3} alt="tiktok" />
      </Link>

      <Link to={snapchat?.value} target="_blank">
        <img src={Soc4} alt="snapchat" />
      </Link>

      <Link to={instagram?.value} target="_blank">
        <img src={Soc5} alt="instagram" />
      </Link>
    </div>
  );
};

export default SocialCon;
