import React, { useState } from "react";
import { LanguageContext } from "./language_context";

const defaultLanguage = { language: localStorage.getItem("language") || "ar" };

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(defaultLanguage);

  const changeLanguage = (lang) => {
    setLanguage({ language: lang });
    localStorage.setItem("language", lang);
  };

  return (
    <LanguageContext.Provider
      value={{
        language: language.language,
        changeLanguage: changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
