import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useContext } from "react";
import { LanguageContext } from "../../store/language_context";
import Header from "../Header";
import Footer from "../Footer";

const ArticleDetail = () => {
  const [article, setArticle] = useState();
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const { id } = useParams();

  useEffect(() => {
    const getSingleArticle = async () => {
      try {
        const { data } = await axios.get(
          `https://albaitdash.mountwolfs.com/api/article/${id}`,
          {
            headers: {
              locale: language,
            },
          }
        );
        setArticle(data?.data);
      } catch (err) {
        console.log(err);
      }
    };
    getSingleArticle();
  }, [id]);

  return (
    <div>
      <Header
        contacts={article?.Contacts}
        siteIdentities={article?.SiteIdentities}
        navLinks={article?.NavLinks}
      />
      <div className="container">
        <h2 style={{ padding: "20px 0 0" }}>
          {article?.SelectedArticle?.title}
        </h2>
        <p
          dangerouslySetInnerHTML={{
            __html: article?.SelectedArticle?.content,
          }}
          style={{ padding: "20px 0" }}
        />
      </div>
      <Footer contacts={article?.Contacts} />
    </div>
  );
};

export default ArticleDetail;
