import React from "react";
import HeadSection from "../elements/HeadSection";
import TapOne from "../elements/serviceTabs/TapOne";

const TopOffer = ({ popularOffers }) => {
  return (
    <div className="Most-Popular Offers">
      <div className="container">
        <HeadSection h3AR={"العروض الأكثر شعبية"} h3EN={"Offers"} />
        <div className="Tabsy">
          <TapOne offers={popularOffers} />
        </div>
      </div>
    </div>
  );
};

export default TopOffer;
