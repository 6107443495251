import React from "react";
import ReactDOM from "react-dom/client";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";
import LanguageProvider from "./store/language_context_provider";
import { BrowserRouter } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </LanguageProvider>
  </React.StrictMode>
);
