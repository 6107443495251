import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "../store/language_context";
import Header from "../components/Header";
import Slider from "../components/elements/Slider";
import FeaturesSec from "../components/sections/FeaturesSec";
import ServiceSec from "../components/sections/ServiceSec";
import OfferSec from "../components/sections/OfferSec";
import TopOffer from "../components/sections/TopOffer";
import BannerSec from "../components/sections/BannerSec";
import BlogSec from "../components/sections/BlogSec";
import DocServSec from "../components/sections/DocServSec";
import Footer from "../components/Footer";
import axios from "axios";

function Home() {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data } = await axios.get(
        "https://albaitdash.mountwolfs.com/api",
        {
          headers: {
            locale: language,
          },
        }
      );
      setHomeData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="Home">
      {/* Header */}
      <Header
        contacts={homeData.Contacts}
        siteIdentities={homeData.SiteIdentities}
        navLinks={homeData.NavLinks}
      />

      {/* Top Section */}
      <Slider headerSlider={homeData?.HeaderSlider} />
      {/* Features */}
      <FeaturesSec features={homeData?.AdvantagesBoxesTexts} />
      {/* Services */}
      <ServiceSec servicesList={homeData?.ServicesList} />
      {/* Offers */}
      <OfferSec offers={homeData?.OffersList} />
      {/* Top Offers */}
      <TopOffer popularOffers={homeData?.MostPopular} />
      {/* Banner */}
      <BannerSec userCounts={homeData?.UsersCounts} />
      {/* Blog */}
      <BlogSec articles={homeData?.LatestArticles} />

      {/* DocSec */}
      <DocServSec
        contacts={homeData?.Contacts}
        MedicalServicesBoxes={homeData?.MedicalServicesBoxes}
      />
      {/* Footer */}
      <Footer contacts={homeData?.Contacts} />
    </div>
  );
}

export default Home;
