import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import HeadSection from "../elements/HeadSection";
import DocServ from "../elements/DocServ";
import { LanguageContext } from "../../store/language_context";

// Import
const App = "/media/app.png";
const Apple = "/media/apple.png";
const Play = "/media/play.png";

const DocServSec = ({ contacts, MedicalServicesBoxes }) => {
  const box1 = MedicalServicesBoxes?.box1;
  const box2 = MedicalServicesBoxes?.box2;
  const box3 = MedicalServicesBoxes?.box3;
  const box4 = MedicalServicesBoxes?.box4;

  const appleApp = contacts?.find((item) => item.option === "app_store_link");
  const googleApp = contacts?.find(
    (item) => item.option === "google_play_link"
  );
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  return (
    <div className="DocServ">
      <HeadSection
        h3AR={"الخدمات الطبية من البيت إلى كل بيت"}
        h3EN={"Medical services from home to every home"}
      />
      <div className="backo">
        <div className="container">
          <div className="all-DocSer">
            <div className="cards">
              <DocServ photo={box1?.image} text={box1?.title} />
              <DocServ photo={box2?.image} text={box2?.title} />
              <DocServ photo={box3?.image} text={box3?.title} />
              <DocServ photo={box4?.image} text={box4?.title} />
            </div>
          </div>

          {/* App Download */}
          <div className="app-down">
            <div className="row">
              <div className="col-md-6">
                <div className="DownLogo">
                  <div className="texte">
                    <h4>
                      {language === "en"
                        ? "Download the app now"
                        : "حمل التطبيق الآن "}
                    </h4>
                    <p>
                      {language === "en"
                        ? "Get all home medical services from the comfort of your home through the Al-Bayt application."
                        : "احصل على جميع الخدمات الطبيه المنزليه وانت مرتاح في ببيتك من خلال تطبيق البيت"}
                    </p>
                  </div>
                  <div className="logos">
                    <Link to={appleApp?.value} target="_blank">
                      <img src={Apple} alt="Apple" />
                    </Link>
                    <Link to={googleApp?.value} target="_blank">
                      \
                      <img src={Play} alt="Play" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="AppPhot">
                  <img src={App} alt="Aft" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocServSec;
