import React from "react";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { useContext } from "react";
import axios from "axios";
import { LanguageContext } from "../../store/language_context";

const Service = ({
  photo,
  icon,
  title,
  excerpt,
  price,
  link,
  content,
  onClick,
  serviceId,
}) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  const increaseClicks = async () => {
    try {
      await axios.post(
        `https://albaitdash.mountwolfs.com/api/addClickToService/${serviceId}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {price && photo && (
        <div className="col-md-3 offer-card" onClick={onClick}>
          <div className="serv">
            <i className={icon}></i>
            <div className="mges">
              <img src={photo} alt="#" />
            </div>
            <div className="title">
              <h4>{title}</h4>
              <ShowMoreText
                lines={1}
                more="المزيد"
                less="اقل"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                expandByClick={true}
                width={150}
                truncatedEndingComponent={"... "}
              >
                <p>{excerpt}</p>
              </ShowMoreText>
            </div>
            <div className="price">
              <span style={{ marginLeft: "10px" }}>{price} SR</span>
              <Link
                to={link}
                className="btn btn-primary reserve-btn"
                target="_blank"
                onClick={increaseClicks}
              >
                {language === "en" ? "Book" : "احجز"}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Service;
