import React from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { LanguageContext } from "../store/language_context";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ServiceInfo from "../components/sections/ServiceInfo";
import { useParams } from "react-router-dom";
import { Paginator } from "primereact/paginator";
function ServiceData() {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const [contentData, setContentData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [showSer, setShowSer] = useState([]);

  const serviceID = useParams().service_id;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(8);

  useEffect(() => {
    getData();
    getSingleSerData();
  }, [first, serviceID]);

  const getSingleSerData = async () => {
    try {
      const { data } = await axios.get(
        `https://albaitdash.mountwolfs.com/api/services/${serviceID}`,
        {
          headers: {
            locale: language,
          },
        }
      );

      let shown = data.data?.SelectedService?.Articles.slice(
        first,
        first + rows
      );

      setShowSer(shown);
      setServiceData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      const { data } = await axios.get(
        `https://albaitdash.mountwolfs.com/api`,
        {
          headers: {
            locale: language,
          },
        }
      );
      setContentData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <div className="Page">
      <Header
        contacts={contentData.Contacts}
        siteIdentities={contentData.SiteIdentities}
        navLinks={contentData.NavLinks}
      />
      <div className="Offers">
        <div className="container">
          <div className="Tabsy">
            <div>
              <div className="row offer-container">
                {showSer.map((item) => {
                  return <ServiceInfo serInfo={item} />;
                })}
              </div>

              <blockquote className="mt-4 p-4">
                <Paginator
                  first={first}
                  rows={rows}
                  dir="ltr"
                  totalRecords={serviceData?.SelectedService?.Articles?.length}
                  onPageChange={onPageChange}
                  template={{
                    layout: "PrevPageLink CurrentPageReport NextPageLink",
                  }}
                />
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <Footer contacts={contentData.Contacts} />
    </div>
  );
}

export default ServiceData;
