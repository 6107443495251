import React from "react";
import { HashRouter, Route, Routes, BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "./store/language_context";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import SingleBlog from "./pages/SingleBlog";
import Contact from "./pages/Contact";
import ServiceDetail from "./components/elements/ServiceDetail ";
import ArticleDetail from "./components/elements/articleDetail";
import ServiceData from "./pages/ServicesData";
import "./App.css";

function App() {
  const [offerData, setOfferData] = useState([]);
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          "https://albaitdash.mountwolfs.com/api",
          {
            headers: {
              locale: language,
            },
          }
        );
        setOfferData(data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [language]);

  const title = offerData?.SiteIdentities?.find(
    (val) => val?.option === "site_name"
  );
  const description = offerData?.SiteIdentities?.find(
    (val) => val?.option === "slogan"
  );
  const icon = offerData?.SiteIdentities?.find(
    (val) => val?.option === "fav_icon"
  );

  const headerScripts = offerData?.SiteIdentities?.find(
    (val) => val?.option === "header_scripts"
  );

  const footerScripts = offerData?.SiteIdentities?.find(
    (val) => val?.option === "footer_scripts"
  );

  return (
    <>
      <Helmet>
        <title>{title?.value}</title>
        <meta name="og:title" content={title?.value} />
        <meta name="description" content={description?.value} />
        <meta name="og:description" content={description?.value} />
        <meta name="twitter:title" content={title?.value} />
        <meta name="twitter:description" content={description?.value} />
        <link
          rel="icon"
          type="image/x-icon"
          href={`https://albaitdash.mountwolfs.com${icon?.value}`}
        />
        {headerScripts && (
          <script type="text/javascript" src={headerScripts.value} />
        )}
      </Helmet>

      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/single" element={<SingleBlog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/:service_id" element={<ServiceData />} />
          <Route
            path="/service/:id"
            element={
              <ServiceDetail
                offers={offerData?.OffersList}
                offerData={offerData}
              />
            }
          />
          <Route
            path="/article/:id"
            element={
              <ArticleDetail
                articleData={offerData}
                rightArticle={offerData?.LatestArticles?.mainArticle}
                leftArticles={offerData?.LatestArticles?.SideArticles}
              />
            }
          />
        </Routes>

        {footerScripts && (
          <script type="text/javascript" src={footerScripts.value} />
        )}
      </HashRouter>

      <style>
        {`
          html {
            direction: ${language === "ar" ? "rtl" : "ltr"};
          }
        `}
      </style>
    </>
  );
}

export default App;
