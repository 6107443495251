import React from "react";
// import { useState, useEffect } from "react";
// import axios from "axios";
import { Link } from "react-router-dom";

// Import Media
// const phot = "/media/blog.png";

const SingleBlog = ({ rightArticle }) => {
  return (
    <div className="single-blog">
      <div className="photo">
        <img
          src={`https://albaitdash.mountwolfs.com${rightArticle?.image}`}
          alt="blog"
        />
      </div>
      <div className="content">
        <h3>{rightArticle?.title}</h3>
        <p>{rightArticle?.excerpt}</p>
      </div>
      <div className="date">
        <Link to={`/article/${rightArticle?.exLink}`}>More...</Link>
      </div>
    </div>
  );
};

export default SingleBlog;
