import React from "react";

const DocServ = ({ photo, text }) => {
  const baseUrl = "https://albaitdash.mountwolfs.com";
  return (
    <div className="">
      <div className="Doc">
        <img src={baseUrl + photo} alt="Doc" />
        <div className="txt">
          <h5>{text}</h5>
        </div>
      </div>
    </div>
  );
};

export default DocServ;
