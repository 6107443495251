import React from "react";
import { LanguageContext } from "../../store/language_context";
import { useContext } from "react";
import { Link } from "react-router-dom";

const HeadSection = ({ h3AR, h3EN, btnAR, btnEN, link }) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  return (
    <div className="head-sec text-center mb-5">
      <h3>{language === "en" ? `${h3EN}` : `${h3AR}`}</h3>
      {btnAR && btnEN && (
        <Link to={link}>
          <button>{language === "en" ? `${btnEN}` : `${btnAR}`}</button>
        </Link>
      )}
    </div>
  );
};

export default HeadSection;
