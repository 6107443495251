import React, { Fragment } from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { LanguageContext } from "../store/language_context";
import Header from "../components/Header";
import TapOne from "../components/elements/serviceTabs/TapOne";
import Footer from "../components/Footer";

function SingleBlog() {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const [offerData, setOfferData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          `https://albaitdash.mountwolfs.com/api`,
          {
            headers: {
              locale: language,
            },
          }
        );
        setOfferData(data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  return (
    <div className="Page">
      <Header
        contacts={offerData.Contacts}
        siteIdentities={offerData.SiteIdentities}
        navLinks={offerData.NavLinks}
      />
      <div className="Offers">
        <div className="container">
          <div className="Tabsy">
            <TapOne offers={offerData?.OffersList} />
          </div>
        </div>
      </div>
      <Footer contacts={offerData.Contacts} />
    </div>
  );
}

export default SingleBlog;
