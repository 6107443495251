import React from "react";
import { useContext } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LanguageContext } from "../../store/language_context";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
const MyNavbar = ({ servicesList }) => {
  const location = useLocation();
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  // Helper function to determine if the link is active
  const isNavLinkActive = (linkPath) => {
    return location.pathname === linkPath;
  };

  return (
    <Navbar expand="md">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link className={isNavLinkActive("/") ? "active" : ""}>
            <Link to={"/"}>{language === "en" ? "Home" : "الرئيسية"}</Link>
          </Nav.Link>
          {/* 
          <NavDropdown
            title={language === "en" ? "Our home services" : "خدماتنا المنزلية"}
            id="basic-nav-dropdown"
          >
            {servicesList?.map((service) => (
              <NavDropdown.Item
                href={`/single/${service?.id}`}
                key={service?.id}
                className={
                  isNavLinkActive(`/single/${service?.id}`) ? "active" : ""
                }
              >
                {service?.category_name}
              </NavDropdown.Item>
            ))}
          </NavDropdown> */}

          <Nav.Link className={isNavLinkActive("/single") ? "active" : ""}>
            <Link to={"/single"}>
              {language === "en" ? "Services" : "العروض"}
            </Link>
          </Nav.Link>

          <Nav.Link className={isNavLinkActive("/blogs") ? "active" : ""}>
            <Link to={"/blogs"}>
              {language === "en" ? "Articles" : "المقالات"}
            </Link>
          </Nav.Link>

          <Nav.Link className={isNavLinkActive("/contact") ? "active" : ""}>
            <Link to={"/contact"}>
              {language === "en" ? "Contact us" : "تواصل معنا"}
            </Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavbar;
