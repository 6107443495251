import React from "react";
import { useContext } from "react";
import SingleBlog from "../elements/blogSec/SingleBlog";
import BlogItem from "../elements/blogSec/BlogItem";
import HeadSection from "../elements/HeadSection";
import { LanguageContext } from "../../store/language_context";

const BlogSec = ({ articles }) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  return (
    <div className="Blogsec">
      <div className="container">
        <HeadSection
          h3AR={"المقالات"}
          h3EN={"Articles"}
          btnAR={"مشاهدة المزيد"}
          btnEN={"Watch More"}
          link={"/blogs"}
        />

        <div className="all-con">
          <div className="right-dev">
            <SingleBlog rightArticle={articles?.mainArticle} />
          </div>

          <div className="left-dev">
            {articles?.SideArticles?.map((article, i) => (
              <BlogItem key={i} article={article} index={i + 1} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSec;
