import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const ServiceDetail = ({ offers, offerData }) => {
  const { id } = useParams();
  const offer = offers?.[id];

  return (
    <div>
      <Header
        contacts={offerData.Contacts}
        siteIdentities={offerData.SiteIdentities}
        navLinks={offerData.NavLinks}
      />
      <h2 style={{ padding: "20px 0 0" }}>{offer?.title}</h2>
      <p
        dangerouslySetInnerHTML={{ __html: offer?.content }}
        style={{ padding: "20px 0" }}
      />
      <Footer contacts={offerData.Contacts} />
    </div>
  );
};

export default ServiceDetail;
