import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../../../store/language_context";

// Import Media
// const phot = "/media/blog.png";

const BlogItem = ({ article, index }) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  return (
    <div className="boxe">
      <img
        src={`https://albaitdash.mountwolfs.com${article?.image}`}
        alt="blog"
      />
      <div className="conte">
        <h4 className="mb-3">{article?.excerpt}</h4>
        <Link to={`/article/${article?.exLink}`}>
          {language === "en" ? "More..." : "المزيد..."}
        </Link>
      </div>
    </div>
  );
};

export default BlogItem;
