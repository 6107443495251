import React, { Fragment } from "react";
import { useContext } from "react";
import axios from "axios";
import { useRef, useState, useEffect } from "react";
import HeadSection from "../components/elements/HeadSection";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { LanguageContext } from "../store/language_context";

function Contact() {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const serviceRef = useRef();
  const messageRef = useRef();

  const [homeData, setHomeData] = useState([]);
  const [services, setServices] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          "https://albaitdash.mountwolfs.com/api",
          {
            headers: {
              locale: language,
            },
          }
        );
        setHomeData(data.data);
      } catch (err) {
        console.log(err);
      }
    };
    const getServices = async () => {
      try {
        const { data } = await axios.get(
          "https://albaitdash.mountwolfs.com/api/contactus"
        );
        setServices(data.data.CategoriesWithServices);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
    getServices();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", nameRef.current.value);
    formData.append("email", emailRef.current.value);
    formData.append("mobile", phoneRef.current.value);
    formData.append("service", serviceRef.current.value);
    formData.append("message", messageRef.current.value);

    try {
      const { data } = await axios.post(
        "https://albaitdash.mountwolfs.com/api/order-service",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Fragment>
      <Header
        contacts={homeData.Contacts}
        siteIdentities={homeData.SiteIdentities}
        navLinks={homeData.NavLinks}
      />

      <div className="ContactPage">
        <div className="container">
          <div className="Heade text-center">
            <HeadSection>
              {language === "en" ? "Contact Us" : "تواصل معنا"}
            </HeadSection>
            <p>
              {language === "en"
                ? "Do you have any questions or would like to book an appointment? We are happy to communicate with you at any time through the following form!"
                : "هل لديك اي استفسار او ترغب بحجز موعد؟ يسعدنا ان نتواصل معا في اي وقت من خلال النموذج التالي!"}
            </p>
          </div>

          <div className="Form">
            <form>
              <div className="form-group mb-3">
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={language === "en" ? "Name" : "الاسم"}
                      ref={nameRef}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="email"
                      className="form-control"
                      placeholder={
                        language === "en" ? "Email" : "البريد الالكتروني"
                      }
                      ref={emailRef}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={
                        language === "en" ? "Phone Number" : "رقم الهاتف"
                      }
                      ref={phoneRef}
                    />
                  </div>
                  <div class="col">
                    <select ref={serviceRef} className="form-select">
                      <option value="0" selected disabled>
                        {language === "en" ? "Service" : "الخدمة"}
                      </option>
                      {services?.map((service) => (
                        <option
                          key={service?.Services?.[0].id}
                          value={service?.Services?.[0].id}
                        >
                          {service?.Services?.[0].title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group mb-3">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder={
                    language === "en" ? "Message" : "اكتب رسالتك هنا"
                  }
                  ref={messageRef}
                ></textarea>
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                onClick={submitHandler}
              >
                {language === "en" ? "Send" : "ارسال"}
              </button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
}

export default Contact;
