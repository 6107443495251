import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import SocialCon from "./elements/SocialCon";
import { LanguageContext } from "../store/language_context";

// Media
const Apple = "/media/apple.png";
const Play = "/media/play.png";

const Footer = ({ contacts }) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  const googleStore = contacts?.find(
    (contact) => contact.option === "google_play_link"
  );
  const appleStore = contacts?.find(
    (contact) => contact.option === "app_store_link"
  );

  return (
    <footer>
      <div className="four-col">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="coly-one">
                <div className="foo-head mb-3">
                  <h4 className="text-light">
                    {" "}
                    {language === "en"
                      ? "Al-Bayt Home Medical Care"
                      : "البيت للرعاية الطبية المنزلية"}
                  </h4>
                </div>
                <p className="text-light">
                  {language === "en"
                    ? "Our mission at Al-Bayt Healthcare is to provide home medical care service. By providing healthcare solutions and distinguished medical services of high quality."
                    : "مهمتنا في البيت للرعاية الصحية هي توفير خدمة الرعاية الطبية المنزلية. عبر تقديم حلول الرعاية الصحية والخدمات الطبية المتميزة ذات جودة عالية."}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="coly-one">
                <div className="foo-head mb-3">
                  <h4 className="text-light">
                    {language === "en" ? "Important Links" : "روابط مهمة"}
                  </h4>
                </div>
                <ul>
                  <li>
                    <Link to="#">
                      {language === "en"
                        ? "Terms and Conditions"
                        : "الشروط والأحكام"}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {language === "en" ? "Privacy Policy" : "سياسة الخصوصية"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="coly-one">
                <div className="foo-head mb-3">
                  <h4 className="text-light">
                    {language === "en" ? "Download the app" : "حمل التطبيق "}
                  </h4>
                </div>
                <div className="app-down22">
                  <Link to={appleStore?.value} target="_blank">
                    <img src={Apple} alt="Apple" width="140px" />
                  </Link>
                  <Link to={googleStore?.value} target="_blank">
                    <img src={Play} alt="Play" width="120px" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="coly-one">
                <div className="foo-head mb-3">
                  <h4 className="text-light">
                    {" "}
                    {language === "en" ? "Follow us on" : "تابعنا على "}
                  </h4>
                </div>
                <SocialCon contacts={contacts} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="botto-dev text-center py-3">
        <p className="text-light fs-6 m-0 p-0">
          {language === "en"
            ? "All Rights Reserved ©  Al-Bayt Home Medical Care"
            : "جميع الحقوق محفوظة ©  البيت للرعاية الطبية المنزلية"}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
