import React from "react";
import HeadSection from "../elements/HeadSection";
import TapOne from "../elements/serviceTabs/TapOne";

const OfferSec = ({ offers }) => {
  return (
    <div className="Offers">
      <div className="container">
        <HeadSection
          h3AR={"العروض"}
          h3EN={"Offers"}
          btnAR={"مشاهدة المزيد"}
          btnEN={"Watch More"}
          link={"/single"}
        />

        <div className="Tabsy">
          <TapOne offers={offers} />
        </div>
      </div>
    </div>
  );
};

export default OfferSec;
