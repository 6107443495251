import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useContext } from "react";
import HeadSection from "../elements/HeadSection";
import BlogPageCard from "../elements/BlogPageCard";
import { LanguageContext } from "../../store/language_context";

const LastNews = ({ articles }) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;

  const [activeTab, setActiveTab] = useState("doc1");

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="LastN">
      <div className="container">
        <HeadSection>
          {language === "en" ? "Last Articles" : "أخر المقالات"}
        </HeadSection>
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabSelect}
          id="uncontrolled-tab-example"
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            paddingBottom: "50px",
          }}
        >
          {articles?.map((article, i) => (
            <Tab
              eventKey={`doc${i + 1}`}
              key={`doc${i + 1}`}
              title={article?.category_name}
            >
              {activeTab === `doc${i + 1}` && (
                <div className="row offer-container">
                  {article?.Articles?.map((single, j) => (
                    <BlogPageCard
                      key={j}
                      phot={`https://albaitdash.mountwolfs.com${single?.image}`}
                      title={single?.title}
                      txt={single?.excerpt}
                      date={single?.createdAt}
                      btnLink={`/article/${single?.exLink}`}
                      index={j + 1}
                    />
                  ))}
                </div>
              )}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default LastNews;
