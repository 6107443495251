import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../../store/language_context";

const BlogPageCard = ({ phot, title, txt, date, index, btnLink }) => {
  const languageContext = useContext(LanguageContext);
  const language = languageContext.language;
  // console.log(btnLink);

  return (
    <>
      {phot && txt && date && (
        <div className="col-md-3">
          <div className="carde">
            <div className="phote">
              <img src={phot} alt="img" />
            </div>
            <div className="conten">
              <h4 className="mb-3">{title}</h4>
              <p>{txt}</p>
            </div>
            <div className="date">
              <span>{date}</span>
              <Link to={btnLink}>
                {language === "en" ? "More..." : "المزيد..."}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogPageCard;
