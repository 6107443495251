import React from "react";
import { Carousel } from "react-bootstrap";

const Slider = ({ headerSlider }) => {
  const slides = headerSlider?.Slides;

  return (
    <Carousel>
      {slides?.map((slide, index) => (
        <Carousel.Item key={index}>
          <div className="overlay">
            <img
              className="d-block w-100"
              src={`https://albaitdash.mountwolfs.com/${slide?.image}`}
              alt={`Slide ${index + 1}`}
            />
            <Carousel.Caption
              className="carousel-caption"
              // style={{ color: "white" }}
            >
              <h2>{slide?.title}</h2>
              <h4>{slide?.excerpt}</h4>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Slider;
