import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const MyNavTow = ({ navLinks }) => {
  const location = useLocation();
  const isActiveLink = (activeLink) => {
    return location.pathname === activeLink;
  };

  return (
    <Navbar expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {navLinks?.map((navLink) => (
            <Nav.Link
              className={
                isActiveLink(`/services/${navLink?.exLink}`) ? "activeNav2" : ""
              }
              key={navLink?.exLink}
            >
              <Link to={`/services/${navLink?.exLink}`}>{navLink?.name}</Link>
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavTow;
